import Cookies from "js-cookie";

// list with following format
// purpose, type, cookieName, app, duration
const cookieList = {
  necessary: [
    [
      "Notwendig",
      "Permanent",
      "gdpr",
      "Cookie Zustimmung",
      "24 Monate",
      "Dieses Cookie wird verwendet, um den Zustimmungsstatus des Benutzers für Cookies zu speichern.",
    ],
  ],
  performance: [
    // [
    //   "Performance & Statistik",
    //   "Permanent",
    //   "gdpr-google-analytics",
    //   "Google Analytics aktiv/nicht aktiv",
    //   "-",
    // ],
    [
      "Performance & Statistik",
      "Permanent",
      "gdpr-google-tagmanager",
      "Google Tagmanager aktiv/nicht aktiv",
      "14 Monate",
      "Dieses Cookie wird verwendet, um den Zustimmungsstatus des Benutzers für Google Analytics Cookies zu speichern.",
    ],
    [
      "Performance & Statistik",
      "Permanent",
      "_gat_UA-195321540-1",
      "Google Universal Analytics",
      "14 Monate",
      "Dieses Cookie speichert Informationen darüber, aus welcher Quelle (von welcher anderen Website bzw. über welche Werbeanzeige) ein User zuletzt auf die Website gekommen ist.",
    ],
    [
      "Performance & Statistik",
      "Permanent",
      "_gid",
      "Google Universal Analytics",
      "24 Stunden",
      "Enthält eine zufallsgenerierte User-ID. Anhand dieser ID kann Google Analytics wiederkehrende User auf dieser Website wiedererkennen und die Daten von früheren Besuchen zusammenführen.",
    ],
    [
      "Performance & Statistik",
      "Permanent",
      "_ga_890BQBYH92",
      "Google Analytics 4",
      "24 Stunden",
      "Dieses Cookie speichert Informationen darüber, aus welcher Quelle (von welcher anderen Website bzw. über welche Werbeanzeige) ein User zuletzt auf die Website gekommen ist.",
    ],
    [
      "Performance & Statistik",
      "Permanent",
      "_ga",
      "Google Tag Manager",
      "2 Jahre",
      "Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der Besucher die Website nutzt, zu generieren.",
    ],
    [
      "Performance & Statistik",
      "Permanent",
      "_gcl_au",
      "Google Tag Manager",
      "3 Monate",
      "Wird von Google AdSense zum Experimentieren mit Werbungseffizienz auf Webseiten verwendet, die ihre Dienste nutzen.",
    ],
  ],
  marketing: [
    [
      "Marketing",
      "Permanent",
      "_fbp",
      "Facebook",
      "90 Tage",
      "Dieses Cookie wird von Facebook verwendet, um eine Reihe von Werbeprodukten anzuzeigen, die entweder auf der Plattform Facebook oder anderen von Facebook betriebenen Plattformen gezeigt werden.",
    ],
    [
      "Marketing",
      "Permanent",
      "TDID",
      "The Trade Desk",
      "365 Tage",
      [
        "Dieses Cookie wird verwendet, um die individuelle Trade Desk User ID zu speichern.",
        "Trade Desk ID (TDID) ist das Haupt-Cookie und die ID, die verwendet wird, um Web-Browser-Profile über die Zeit hinweg auf verschiedenen Websites zu erkennen.",
      ],
    ],
    [
      "Marketing",
      "Permanent",
      "TDCPM",
      "The Trade Desk",
      "365 Tage",
      "Trade Desk Cookie Partner Mapping (TDCPM) zeichnet auf, mit welchen Partnern The Trade Desk IDs abgeglichen hat, um redundante Abgleichsaufrufe zu vermeiden. Das Cookie wird verwendet, um die Benutzerinteraktion zu analysieren und welche Werbemaßnahmen der Benutzer gesehen hat, bevor dieser die Website besucht hat.  ",
    ],
    [
      "Marketing",
      "Permanent",
      "TTDOptOut",
      "The Trade Desk",
      "5 Jahre",
      "TTDOptOut speichert einen statischen Wert, der die Entscheidung des Benutzers, sich abzumelden, festhält. Es sollte sich von den anderen Cookies ausschließen, so dass The Trade Desk keine ID im Browser hat, wenn das TTDOptOut-Cookie vorhanden ist.",
    ],
    [
      "Marketing",
      "Permanent",
      "TTDOptOutOfDataSale",
      "The Trade Desk",
      "5 Jahre",
      "TTDOptOutOfDataSale speichert einen statischen Wert, der die Entscheidung des Benutzers aufzeichnet, dem Verkauf von Daten an Dritte zu widersprechen. ",
    ],
  ],
};

const getGdprCookie = () => Cookies.getJSON("gdpr");
const setGdprCookie = settings => Cookies.set("gdpr", settings);
const gdprCookie = getGdprCookie();

const setGatsbyGdprCookies = () => {
  const cookieSettings = getGdprCookie();
  if (!cookieSettings) return;

  const useStatistics = cookieSettings.statistics === true;
  // const statisticCookies = ["gdpr-google-analytics", "gdpr-google-tagmanager"];
  const statisticCookies = ["gdpr-google-tagmanager"]; // analytics is implemented via GTM
  // see gatsby-config.js `gatsby-plugin-gdpr-cookies` settings
  // will trigger those needed cookies
  statisticCookies.forEach(cookie =>
    useStatistics ? Cookies.set(cookie, "true") : Cookies.remove(cookie)
  );
};

export { cookieList, gdprCookie, setGdprCookie, setGatsbyGdprCookies };
