import React, { createContext, useReducer } from "react";

import { default as _get } from "lodash/get";

import { gdprCookie, setGdprCookie, setGatsbyGdprCookies } from "./cookies";

const APPROVE_ALL_COOKIES = "APPROVE_ALL_COOKIES";
const CHANGE_COOKIE = "CHANGE_COOKIE";
const TOGGLE_COOKIE_DIALOG = "TOGGLE_COOKIE_DIALOG";
const TOGGLE_COOKIE_NOTICE = "TOGGLE_COOKIE_NOTICE";

const initialState = {
  cookies: {
    // use current cookie value as initial state
    required: _get(gdprCookie, "required", true),
    // marketing: _get(gdprCookie, "marketing", false),
    statistics: _get(gdprCookie, "statistics", false),
  },
  isOpenDialog: false,
  isOpenNotice: typeof gdprCookie === "undefined",
};
const cookieStore = createContext(initialState);
const { Provider } = cookieStore;

// set initial cookies for `gatsby-plugin-gdpr-integration``
setGatsbyGdprCookies();

const CookieStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case APPROVE_ALL_COOKIES:
        const cookiesApproved = {};
        Object.keys(initialState.cookies).forEach(
          cookieType => (cookiesApproved[cookieType] = true)
        );

        setGdprCookie(cookiesApproved);

        return {
          ...state,
          cookies: cookiesApproved,
        };

      case CHANGE_COOKIE:
        const newCookieState = {
          ...state.cookies,
          ...action.payload,
        };

        setGdprCookie(newCookieState);

        return {
          ...state,
          cookies: newCookieState,
        };

      case TOGGLE_COOKIE_DIALOG:
        // if action.payload is set, this boolean value will be used
        // otherwise it will just toggle the previous state
        return {
          ...state,
          isOpenDialog:
            typeof action.payload === "boolean"
              ? action.payload
              : !state.isOpenDialog,
        };

      case TOGGLE_COOKIE_NOTICE:
        // if action.payload is set, this boolean value will be used
        // otherwise it will just toggle the previous state
        return {
          ...state,
          isOpenNotice:
            typeof action.payload === "boolean"
              ? action.payload
              : !state.isOpenNotice,
        };

      default:
        throw new Error();
    }
  }, initialState);

  const approveAllCookies = () => {
    dispatch({
      type: APPROVE_ALL_COOKIES,
    });
  };

  const changeCookie = payload => {
    dispatch({
      type: CHANGE_COOKIE,
      payload,
    });
  };

  const toggleCookieDialog = status => {
    dispatch({
      type: TOGGLE_COOKIE_DIALOG,
      payload: status,
    });
  };

  const toggleCookieNotice = status => {
    dispatch({
      type: TOGGLE_COOKIE_NOTICE,
      payload: status,
    });
  };

  return (
    <Provider
      value={{
        state,
        dispatch,
        approveAllCookies,
        changeCookie,
        toggleCookieDialog,
        toggleCookieNotice,
      }}
    >
      {children}
    </Provider>
  );
};

export { initialState, cookieStore, CookieStateProvider };
