import React, { useEffect, useState } from "react";

import { getClassName } from "../../0-electrons/css";
import * as css from "./ScrollToTop.module.scss";
import { Button } from "../Button/Button";

const isSSR = typeof window !== "object";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isSSR) {
      // Show button when page is scrolled upto given distance
      const toggleVisibility = () => {
        if (window.pageYOffset > window.innerHeight) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      /* Feature detection */
      // let passiveIfSupported = false;

      // try {
      //   window.addEventListener(
      //     "test",
      //     null,
      //     Object.defineProperty({}, "passive", {
      //       get: function () {
      //         passiveIfSupported = { passive: true };
      //       },
      //     })
      //   );
      // } catch (err) {}

      window.addEventListener(
        "scroll",
        () => requestAnimationFrame(toggleVisibility)
        // passiveIfSupported
      );
    }
  }, []);

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    if (!isSSR) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={getClassName(css, "ScrollToTop")}>
      {isVisible && (
        <Button
          icon="arrowUp"
          iconOnly
          invert
          shadow
          onClick={scrollToTop}
          type="primary"
          animation="up"
        />
      )}
    </div>
  );
}
