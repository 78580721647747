import { default as _camelCase } from "lodash/camelCase";
import { default as _get } from "lodash/get";

/**
 * Get the CSS modules className
 *
 * @param cssObject The CSS module/class name mapping object
 * @returns CSS module className
 */
export const getClassName = (
  cssObject: {
    [key: string]: string;
  },
  className: string
): string | undefined => {
  // fallback for incompatibility between Storybook 6.2.0-rc4 and Gatsby V3
  const cssClassNames =
    typeof cssObject !== "undefined" &&
    cssObject.hasOwnProperty("default") &&
    typeof cssObject.default === "object"
      ? cssObject.default
      : cssObject;
  const hasDashes = className.indexOf("-") > -1;
  // see @url https://github.com/webpack-contrib/css-loader/blob/2599438663f556082113be2c067791caa9b090cd/src/utils.js#L587
  const dashesCamelCase = str =>
    str.replace(/-+(\w)/g, (match, firstLetter) => firstLetter.toUpperCase());
  const localName = hasDashes ? dashesCamelCase(className) : className;

  return _get(cssClassNames, localName, undefined);
};
