import React, { useState } from "react";
import { getClassName } from "../../0-electrons/css";

import Button from "../../1-atoms/Button/Button";
import Clone from "../../0-electrons/Clone/Clone";
import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";
import { ModalSimple } from "../../1-atoms/Modal/Modal";

import * as css from "./QuizPopup.module.scss";

export interface QuizPopupProps {
  children?: React.ReactNode;
  headline: string;
  image: React.ReactElement;
  isVisible: boolean;
  onClose: () => void;
  onStart: () => void;
  startLabel?: string;
}

const QuizPopup: React.FC<QuizPopupProps> = ({
  children,
  headline,
  image,
  isVisible,
  onClose,
  onStart,
  startLabel = "Test starten",
}) => {
  return (
    <ModalSimple
      className={getClassName(css, "QuizPopup")}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className={getClassName(css, "QuizPopup__wrapper")}>
        <Button
          animation="disabled"
          className={getClassName(css, "QuizPopup__wrapper--button-close")}
          icon="cancel"
          iconOnly
          invert
          onClick={onClose}
          type="primary"
        />
        <div className={getClassName(css, "QuizPopup__wrapper--top")}>
          {image !== null ? (
            <Clone
              node={image}
              className={getClassName(css, "QuizPopup__wrapper--top__image")}
            />
          ) : null}
          <TypographyHeadline
            size="h3"
            className={getClassName(css, "QuizPopup__wrapper--top__headline")}
            tag="h3"
            color="white"
          >
            {headline}
          </TypographyHeadline>
        </div>
        <div className={getClassName(css, "QuizPopup__wrapper--bottom")}>
          {children ? (
            <TypographyCopy
              className={getClassName(css, "QuizPopup__wrapper--bottom__copy")}
              size="small"
              color="grey"
            >
              {children}
            </TypographyCopy>
          ) : null}
          <Button type="primary" onClick={onStart}>
            {startLabel}
          </Button>
        </div>
      </div>
    </ModalSimple>
  );
};
export default QuizPopup;
