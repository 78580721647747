import React from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import * as css from "./TypographyHeadline.module.scss";

export const colors = ["default", "grey", "white"];
export const sizes = ["default", "h2", "h3", "h4", "h5"]; // default = h1
export const tags = ["h1", "h2", "h3", "h4", "h5", "h6"];

export interface TypographyHeadlineProps {
  children?: React.ReactNode;
  className?: string;
  color?: typeof colors[number];
  disableSpacing?: boolean;
  size?: typeof sizes[number];
  tag?: typeof tags[number];
  type?: string;
}

export const TypographyHeadline: React.FC<TypographyHeadlineProps> = ({
  children,
  className,
  color,
  disableSpacing = false,
  size,
  tag = "h1",
  type,
}: TypographyHeadlineProps) => {
  const Headline = `${tag}`;
  const colorClass = getClassName(css, `headline--color-${color}`);
  const typeClass = getClassName(css, `headline--type-${type}`);
  const disableSpacingClass = getClassName(css, `headline--disable-spacing`);

  return (
    <Headline
      className={classNames(
        getClassName(css, "headline"),
        getClassName(css, `headline--size-${size}`),
        {
          [colorClass as string]: typeof colorClass !== "undefined",
          [className as string]:
            typeof className === "string" && className.length > 0,
          [typeClass as string]: typeof typeClass !== "undefined",
          [disableSpacingClass as string]: disableSpacing === true,
        }
      )}
    >
      {children}
    </Headline>
  );
};

export default TypographyHeadline;
