// TODO: add TypeScript typings
import React, { useState } from "react";
import classNames from "classnames";
import { default as _get } from "lodash/get";

import { getClassName } from "../../0-electrons/css";
import NavItem from "../../1-atoms/NavItem/NavItem";

import * as css from "./NavMenu.module.scss";

// Menu Wrapper: First Level
const NavMenu = ({ items, onClick, onClose, onNavChange, itemActive }) => {
  return (
    <ul className={getClassName(css, "Menu")}>
      {items?.map((item, index) => {
        const itemHasChildren =
          Array.isArray(item.children) && item.children.length > 0;

        return (
          <MenuLevelOneItem
            key={item?.current.children}
            hasChildren={itemHasChildren}
            item={item}
            isActive={itemActive === index}
            onClick={() => onClick(index)}
            onClose={onClose}
            onNavChange={onNavChange}
          />
        );
      })}
    </ul>
  );
};

// Menu Item: First Level
const MenuLevelOneItem = ({
  hasChildren,
  item,
  isActive,
  onClick,
  onClose,
  onNavChange,
}) => {
  return (
    <li className={getClassName(css, "Menu__Item")}>
      <NavItem
        // type={navItemType}
        type={hasChildren ? undefined : "firstLevelItem"}
        onClick={
          hasChildren
            ? e => {
                e.preventDefault();
                // console.log("set is active");
                // setIsActive(!isActive);
                onClick();
              }
            : () => onNavChange()
        }
        highlight={item.highlight === true}
      >
        {item.current}
      </NavItem>

      {/* pass down item as it has the current item (needed for back link on mobile) and the (optional) children */}
      <MenuLevelTwo
        item={item}
        isActive={isActive}
        onClose={onClose}
        onNavChange={onNavChange}
      />
    </li>
  );
};

// Menu Wrapper: Second Level
const MenuLevelTwo = ({ item, isActive, onClose, onNavChange }) => {
  const children = _get(item, "children", undefined);

  const hasChildren = Array.isArray(children) && children.length > 0;

  if (!hasChildren) return null;

  // use item.current as base, but change label to 'Übersicht'
  const itemOverview = {
    ...item.current,
    props: { ...item.current.props, children: "Übersicht" },
  };

  return (
    <ul
      className={classNames(getClassName(css, "MenuLevelTwo"), {
        [getClassName(css, "MenuLevelTwo--active") as string]: isActive,
      })}
    >
      <li className={getClassName(css, "MenuLevelTwo__Back")}>
        <NavItem
          type="secondLevelParent"
          onClick={e => {
            e.preventDefault();
            // console.log("onClose", onClose);
            onClose();
          }}
        >
          {item.current}
        </NavItem>
      </li>

      {/* "Übersicht" item */}
      <li>
        <NavItem type="secondLevelItem" onClick={() => onNavChange()}>
          {itemOverview}
        </NavItem>
      </li>

      {children.map((child, j) => {
        const isImage = _get(child, "type", undefined) === "image";

        return (
          <React.Fragment key={j}>
            <li>
              {isImage ? (
                <NavItem
                  type="secondLevelItemImage"
                  image={{
                    img: child.image,
                    text: child.text,
                  }}
                >
                  {child.element}
                </NavItem>
              ) : (
                <NavItem type="secondLevelItem" onClick={() => onNavChange()}>
                  {child}
                </NavItem>
              )}
            </li>
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default NavMenu;
