import React, { useState } from "react";

import { getClassName } from "./../../0-electrons/css";
import { Button } from "../../1-atoms/Button/Button.tsx";
import { Modal } from "../../1-atoms/Modal/Modal";
import Toggle from "../../1-atoms/Toggle/Toggle";
import { Tab, Tabs } from "../../2-molecules/Tabs/Tabs";

import * as css from "./CookieLayer.module.scss";

function CookieLayer({ modalTitle, isVisible, onApprove, onClose, tabs = [] }) {
  return (
    <Modal
      className={getClassName(css, "CookieLayer")}
      title={modalTitle}
      isVisible={isVisible}
      onClose={onClose}
    >
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label}>
            <div className={getClassName(css, "CookieLayer__tab")}>
              <strong className={getClassName(css, "CookieLayer__headline")}>
                {tab.headline}
              </strong>
              <Toggle
                className={getClassName(css, "CookieLayer__toggle")}
                isDisabled={tab.isDisabled}
                isActive={tab.value}
                onChange={
                  typeof tab.onChange === "function" ? tab.onChange : null
                }
              >
                {tab.isToggled ? "Aktiv" : `${tab.toggleOption}`}
              </Toggle>
            </div>
            <p className={getClassName(css, "CookieLayer__copy")}>
              {tab.content}
            </p>
          </Tab>
        ))}
      </Tabs>
      <Button
        className={getClassName(css, "CookieLayer__button")}
        icon="arrowRight"
        type="primary"
        onClick={onApprove}
        tag="button"
      >
        Bestätigen
      </Button>
    </Modal>
  );
}

export default CookieLayer;
