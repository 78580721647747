import React from "react";

import { getClassName } from "../../0-electrons/css";
import Clone from "../../0-electrons/Clone/Clone";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";

import * as css from "./Footer.module.scss";

export interface FooterProps {
  /**The Footers children */
  children?: string;
  /**The Footers logoItems */
  logoItems: Array<React.ReactElement>;
  /**The Footers menuItems */
  menuItems: Array<React.ReactElement>;
}

const Footer: React.FC<FooterProps> = ({
  logoItems,
  children,
  menuItems,
}: FooterProps) => {
  const logoItem = logoItems.map((item, i) =>
    i > 0 ? (
      <Clone
        node={item}
        key={`logo-${i}`}
        className={getClassName(css, "footer__logoItem")}
      />
    ) : null
  );

  return (
    <footer className={getClassName(css, "footer")}>
      <div className={getClassName(css, "footer__logo-wrapper")}>
        <Clone
          node={logoItems[0]}
          className={getClassName(css, "footer__logoItem")}
        />
        {logoItem}
      </div>
      <div className={getClassName(css, "footer__menu")}>
        <div className={getClassName(css, "footer__menuItems")}>
          {menuItems.map((item, i) => (
            <Clone
              key={`footer-nav-${i}`}
              className={getClassName(css, "footer__menuItem")}
              node={item}
            />
          ))}
        </div>

        <TypographyCopy
          color="white"
          size="small"
          className={getClassName(css, "footer__copy")}
        >
          {children}
        </TypographyCopy>
      </div>
    </footer>
  );
};

export default Footer;
