import React from "react";

import { Link } from "gatsby";

import { GatsbySeo } from "gatsby-plugin-next-seo";
import { StaticImage } from "gatsby-plugin-image";

import { default as _get } from "lodash/get";

import Banner from "./components/2-molecules/Banner/Banner";
import Footer from "./components/2-molecules/Footer/Footer";

import ContentfulNavigation from "./contentful/ContentfulNavigation";

// Cookie Layer
import { CookieStateProvider } from "./cookie-layer/context";
import CookieLayer from "./cookie-layer/Layer";

import { page } from "./Layout.module.scss";
import { ReactComponent as PierreFabre } from "./images/partner/pierre-fabre.svg";
import ScrollToTop from "../src/components/1-atoms/ScrollToTop/ScrollToTop";

import SkinQuizPopup from "./skin-quiz/popup";

const wrapPageElement = ({ element, props }) => {
  const url = typeof window !== "undefined" && window.location.href;

  return (
    <CookieStateProvider>
      <GatsbySeo
        title="#hautstark"
        metaTags={[
          {
            // temporary until DNS verfication is done
            name: "google-site-verification",
            content: "AX3CSuBdksXmbw-IWllw3FYslc2nuCE0XIW1zZTGUmk",
          },
        ]}
      />

      <ContentfulNavigation />

      {typeof window !== "undefined" ? (
        <>
          {url.indexOf("apotheken") > -1 ||
          url.indexOf("/neurodermitis/uebersicht") > -1 ||
          url.indexOf("/behandlung/uebersicht") > -1 ||
          url.indexOf("/hauttest") > -1 ||
          url.indexOf("hautpflege") > -1 ? null : (
            <Banner title="persönliche Beratung?">
              <a
                href="https://www.hautstark.com/apotheken/?utm_source=internal+link&utm_medium=banner&utm_campaign=hautstark"
                rel="noopener"
              >
                Jetzt Apotheke in deiner Nähe finden!
              </a>
            </Banner>
          )}
        </>
      ) : null}

      <main className={page}>
        {element}
        {typeof window !== "undefined" ? <CookieLayer /> : null}
        <SkinQuizPopup />
      </main>

      <Footer
        logoItems={[
          <PierreFabre key="pierre-fabre" />,
          <a
            key="avene"
            href="https://www.eau-thermale-avene.de/"
            rel="noopener"
          >
            <StaticImage
              src="./images/partner/avene.png"
              transformOptions={{
                grayscale: true,
              }}
              placeholder="none"
              height={50}
              alt="Eau Thermale Avène"
            />
          </a>,
          <a key="A-DERMA" href="https://www.aderma.de/" rel="noopener">
            <StaticImage
              src="./images/partner/a-derma.png"
              placeholder="none"
              height={50}
              alt="A-DERMA"
            />
          </a>,
          <a key="ducray" href="https://www.ducray.com/de-de" rel="noopener">
            <StaticImage
              src="./images/partner/ducray.png"
              placeholder="none"
              height={50}
              alt="Ducray"
            />
          </a>,
          <a key="dexeryl" href="https://www.dexeryl.de/" rel="noopener">
            <StaticImage
              src="./images/partner/dexeryl.png"
              placeholder="none"
              height={50}
              alt="Dexeryl"
            />
          </a>,
          <a key="dnb" href="https://www.neurodermitis-bund.de/" rel="noopener">
            <StaticImage
              src="./images/partner/dnb.png"
              placeholder="none"
              height={49}
              alt="Deutscher Neurodermitis Bund"
            />
          </a>,
        ]}
        menuItems={[
          <Link key="footer-nav-datenschutz" to="/datenschutz/">
            Datenschutz
          </Link>,
          <Link key="footer-rechtliche-hinweise" to="/rechtliche-hinweise/">
            Rechtliche Hinweise
          </Link>,
          <Link key="footer-nav-impressum" to="/impressum/">
            Impressum
          </Link>,
        ]}
      >
        © Pierre Fabre Konzern 2021
      </Footer>
      <ScrollToTop />
    </CookieStateProvider>
  );
};

export default wrapPageElement;
