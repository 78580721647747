import React, { useState, useCallback } from "react";
import classNames from "classnames";

import { getClassName } from "./../../0-electrons/css";
import * as css from "./Tabs.module.scss";

const TabNav = ({ activeTab, handleActiveTab, items }) => (
  <div className={getClassName(css, "Tabs__nav")}>
    {items.map(item => (
      <button
        onClick={e => {
          e.preventDefault();
          handleActiveTab(item.props.label);
        }}
        className={classNames(getClassName(css, "Tabs__button"), {
          [getClassName(css, "Tabs__button--active")]:
            item.props.label === activeTab,
        })}
        key={item.props.label}
      >
        {item.props.label}
      </button>
    ))}
  </div>
);

const TabContent = ({ activeTab, items }) => (
  <div className={getClassName(css, "Tabs__content")}>
    {items.filter(item => item.props.label === activeTab)}
  </div>
);

function Tabs({ children }) {
  const initialTab = children[0].props.label;
  const [activeTab, setActiveTab] = useState(initialTab);

  function handleActiveTab(label) {
    setActiveTab(label);
  }

  return (
    <div className={getClassName(css, "Tabs")}>
      <TabNav
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        items={children}
      />

      <TabContent activeTab={activeTab} items={children} />
    </div>
  );
}

function Tab(props) {
  return <>{props.children}</>;
}

export { Tabs, Tab };
