/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
 
import CustomLayout from "./src/Layout";

// don't forget to add those fonts in .storybook/preview.js
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/bebas-neue";

export const wrapPageElement = CustomLayout;
