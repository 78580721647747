import React from "react";
import { default as _isEmpty } from "lodash/isEmpty";

export interface CloneProps {
  /**
   * The React Element to clone
   */
  node: React.ReactElement;
  /**
   * The className to append to the cloned React Element
   */
  className?: string;
  /**
   * The optional children of the cloned React Element
   */
  children?: React.ReactNode;
  /**
   * Optional onClick Handler
   */
  onClick?: React.MouseEventHandler;
}

export const Clone: React.FC<CloneProps> = ({
  node,
  className,
  onClick,
}: CloneProps) => {
  const cloneProps = {
    ...node.props,
    onClick,
  };

  const classNames = [];
  if (!_isEmpty(cloneProps.className)) classNames.push(cloneProps.className);
  if (!_isEmpty(className)) classNames.push(className);

  cloneProps.className = classNames.length > 0 ? classNames.join(" ") : null;

  return React.cloneElement(node, cloneProps);
};

export default Clone;
