import React, { useEffect, useState } from "react";

import { default as _get } from "lodash/get";

import { StaticImage } from "gatsby-plugin-image";

import { navigate } from "gatsby";
import QuizPopup from "../components/3-organisms/QuizPopup/QuizPopup";

// TODO: clear hash on reload
function useSkinQuizPopup(timeout = 55, excludeOnPathname = "/hauttest/") {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      // only show popup if the current location does not match "exludeOnPathname"
      const pathname = _get(window, "location.pathname", undefined);

      if (
        typeof pathname === "string" &&
        !pathname.includes(excludeOnPathname)
      ) {
        setShowPopup(true);
      }
    }, timeout * 1000);

    () => {
      clearTimeout(timer);
    };
  }, []);

  return [showPopup, setShowPopup];
}

const SkinQuizPopup = () => {
  const [showPopup, setShowPopup] = useSkinQuizPopup(55);

  const closePopup = () => setShowPopup(false);

  return showPopup ? (
    <QuizPopup
      headline="Nicht sicher, in welcher Neurodermitis-Phase du dich befindest?"
      image={
        <StaticImage
          src="../images/hautklar_2021_03_311352_b.jpeg"
          placeholder="none"
          width={405}
          height={304}
          alt="Hauttest"
        />
      }
      isVisible={showPopup}
      onClose={closePopup}
      onStart={() => {
        closePopup();
        navigate("/hauttest/");
      }}
      startLabel="Zum Hauttest"
    >
      Finde es mit dem Hauttest heraus und erhalte hilfreiche Tipps.
    </QuizPopup>
  ) : null;

  QuizPopup;
};

export default SkinQuizPopup;
