import React from "react";
import classnames from "classnames";

import { Button } from "../Button/Button";
import TypographyCopy from "../TypographyCopy/TypographyCopy";

import { getClassName } from "../../0-electrons/css";
import * as css from "./Modal.module.scss";

export interface ModalProps {
  /** Modal visible or closed */
  isVisible?: boolean | undefined;
  /** Headline for Modal */
  title?: string;
  /** Modal content */
  children: React.ReactNode;
  /** onClose function */
  onClose: React.MouseEventHandler;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isVisible,
  title,
  children,
  onClose,
}: ModalProps) => {
  return isVisible ? (
    <div className={getClassName(css, "Modal")}>
      <div className={getClassName(css, "Overlay")} onClick={onClose}>
        <div
          className={getClassName(css, "Layer")}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Button
            animation="disabled"
            className={getClassName(css, "Modal__btn--close")}
            icon="cancel"
            iconOnly
            invert
            onClick={onClose}
            type="primary"
          />

          <div className={getClassName(css, "Layer__container")}>
            <TypographyCopy className={getClassName(css, "Layer__title")}>
              {title}
            </TypographyCopy>
            <div className={getClassName(css, "Layer__content")}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export const ModalSimple: React.FC<ModalProps> = ({
  isVisible,
  onClose,
  children,
}: ModalProps) => {
  return isVisible ? (
    <div className={getClassName(css, "Modal")}>
      <div className={getClassName(css, "Overlay")} onClick={onClose}>
        <div
          className={getClassName(css, "LayerSimple")}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export default { Modal, ModalSimple };
