import React from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";

import * as css from "./TypographyCopy.module.scss";

export interface TypographyCopyProps {
  children?: React.ReactNode;
  color?: string;
  className?: string;
  size?: string;
}

export const TypographyCopy: React.FC<TypographyCopyProps> = ({
  children,
  color,
  className,
  size = "default",
}: TypographyCopyProps) => {
  const colorClass = getClassName(css, `copy--color-${color}`);

  return (
    <p
      className={classNames(
        getClassName(css, "copy"),
        getClassName(css, `copy--size-${size}`),
        {
          [colorClass as string]: typeof colorClass !== "undefined",
          [className as string]:
            typeof className === "string" && className.length > 0,
        }
      )}
    >
      {children}
    </p>
  );
};

export default TypographyCopy;
