import React, { MouseEventHandler } from "react";
import classNames from "classnames";

import { default as _isEmpty } from "lodash/isEmpty";

import { getClassName } from "../../0-electrons/css";
import Clone from "../../0-electrons/Clone/Clone";
import { Icon, IconProps, iconExists } from "../../0-electrons/Icon/Icon";

import * as css from "./Button.module.scss";
export const buttonTypes = [
  "primary",
  "secondary",
  "secondary-fill",
  "text",
  "menu-open",
  "menu-close",
];
export const buttonTags = ["a", "button"];
export const animationDirections = ["disabled", "up", "down", "left", "right"];

interface ButtonChildrenProps {
  /** disabled or not */
  disabled?: boolean;
  /** The Icon to use */
  icon?: IconProps["type"] | false;
  /** If the button should only display an icon (uses style modifier) */
  iconOnly?: boolean | undefined;
  //removes border from the button
  borderless?: boolean | undefined;
  /** invert button mode (uses style modifiers) */
  invert?: boolean | undefined;
  /** use shadow */
  shadow?: boolean | undefined;
  /** The Buttons Label */
  children?: React.ReactNode;
  /** The links href (you have to use tag a in combination) */
  href?: string;
  /** The links rel */
  rel?: string;
  /** The links target */
  target?: string;
  /** The tag to use: button or a */
  tag?: typeof buttonTags[number];
  /** The Buttons type */
  type?: typeof buttonTypes[number]; // just change the array in buttonTypes to get the correct Union Type
  /** Direction of hover animation */
  animation?: typeof animationDirections[number];
}

export interface ButtonProps extends ButtonChildrenProps {
  /** className to add */
  className?: string | undefined;
  /** ReactElement to use as the button, will override tag and type  */
  node?: React.ReactNode | undefined;
  /** Function to trigger on click */
  onClick?: MouseEventHandler;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  disabled,
  icon,
  iconOnly,
  borderless = false,
  invert = false,
  shadow = false,
  href,
  target,
  rel,
  node,
  tag = "a",
  type = "text",
  animation = "right",
  onClick,
  ...propsRest
}: ButtonProps) => {
  const dataProps = getDataAttributes(propsRest);

  const Tag = `${tag}` as React.ReactNode;

  // button specific props/settings
  const isButton = tag === "button";
  const buttonProps = isButton ? { type: "button", onClick, disabled } : null;

  // link specific props/settings
  const isLink = tag === "a";
  const linkProps = isLink ? { href, onClick, rel, target } : null;

  // classNames
  const classes = classNames(
    getClassName(css, "Button"),
    getClassName(css, `Button--type-${type}`),
    {
      [className as string]: !_isEmpty(className),
      [getClassName(css, `Button--mode-cta`) as string]:
        type === "primary" ||
        type === "secondary" ||
        type === "secondary-fill" ||
        type === "tertiary",
      [getClassName(css, "Button--mode-invert") as string]: invert,
      [getClassName(css, "Button--shadow") as string]: shadow,
      [getClassName(css, "Button--icon-only") as string]: iconOnly,
      [getClassName(css, "Button--borderless") as string]: borderless,
      [getClassName(css, "Button--disabled") as string]: disabled,
    }
  );

  const buttonChildren = (_children: ButtonChildrenProps) => (
    <ButtonChildren
      animation={animation}
      icon={icon}
      iconOnly={iconOnly === true ? true : undefined}
      invert={invert === true ? true : undefined}
      shadow={shadow === true ? true : undefined}
      rel={rel}
      target={target}
      type={type}
      disabled={disabled}
    >
      {_children}
    </ButtonChildren>
  );

  return React.isValidElement(node as React.ReactElement) ? (
    // usage of outer node, i.e. a gatsby Link component
    <Clone
      node={{
        ...node,
        props: {
          ...node.props,
          ...buttonProps,
          children: buttonChildren(node.props.children),
        },
      }}
      className={classes}
    />
  ) : (
    // "normal" usage
    <Tag className={classes} {...buttonProps} {...linkProps} {...dataProps}>
      {buttonChildren(children)}
    </Tag>
  );
};

const ButtonChildren: React.FC<ButtonChildrenProps> = ({
  icon,
  iconOnly,
  invert = false,
  shadow = false,
  children,
  type = "text",
  animation,
}: ButtonChildrenProps) => {
  if (type === "menu-close" || type === "menu-open") {
    return <Icon type={type === "menu-close" ? "cancel" : "menu"} />;
  }

  const hasAnimation =
    typeof animation === "string" && animation !== "disabled";

  // set arrow right icon, when it is a primary, secondary or text button and no icon is set explicitly
  // icon explicitely set to false won't use the fallback
  const iconFallback =
    icon !== false &&
    !_isEmpty(type) &&
    type &&
    ["primary", "secondary", "text"].indexOf(type) > -1
      ? "arrowRight"
      : null;

  return (
    <>
      {/* Label when set */}
      {!_isEmpty(children) && children ? children : null}

      {/* Icon when set */}
      {(!_isEmpty(icon) && icon && iconExists(icon)) ||
      (!_isEmpty(iconFallback) && iconFallback) ? (
        <Icon
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          type={icon || iconFallback!}
          color={getIconColor(type, invert)}
          className={classNames(getClassName(css, "Button__icon"), {
            [getClassName(css, "Button__icon--not-single") as string]:
              !iconOnly,
            [getClassName(css, "Button__icon--animation") as string]:
              hasAnimation,
            [getClassName(
              css,
              `Button__icon--animation-${animation}`
            ) as string]: hasAnimation,
          })}
        />
      ) : null}
    </>
  );
};

function getDataAttributes(props: any) {
  const dataProps = {} as any;
  Object.keys(props)
    // check if it is a data prop
    .filter(key => key.indexOf("data") === 0)
    // add to dataProps after filtering
    .forEach(key => (dataProps[key as string] = props[key as string]));

  if (Object.keys(dataProps).length > 0) {
    return dataProps;
  }

  return undefined;
}

function getIconColor(
  type: ButtonChildrenProps["type"],
  invert: ButtonChildrenProps["invert"]
) {
  switch (type) {
    case "primary":
      return invert === true ? "primary" : "white";
    case "text":
    case "secondary":
      return invert === true ? "white" : "primary";
    case "tertiary":
      return invert === true ? "grey-50" : "primary";
    default:
      return "primary";
  }
}

export default Button;
