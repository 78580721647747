import React from "react";

import { default as _trimEnd } from "lodash/trimEnd";
import { default as _trimStart } from "lodash/trimStart";

/**
 * Check if string has correct type and is not empty (length > 0)
 * @param str string to check
 * @returns Boolean if the string is not empty and has length
 */
export function stringNotEmpty(str: string | undefined): boolean {
  return typeof str === "string" && str.length > 0;
}

/**
 * Remove beginning slash of a string ("/path/name/" -> "path/name/")
 * @param str String to check
 * @returns String without beginning slash
 */
export function removeBeginningSlash(str: string): string {
  return _trimStart(str, "/");
}

/**
 * Remove trailing slash of a string ("/path/name/" -> "/path/name")
 * @param str String to check
 * @returns String without trailing slash
 */
export function removeTrailingSlash(str: string): string {
  return _trimEnd(str, "/");
}

/**
 * Remove beginning and trailing slash of a string ("/path/name/" -> "path/name")
 * @param str String to check
 * @returns String without beginning and trailing slash
 */
export function removeBeginningAndTrailingSlash(str: string): string {
  const withoutBeginningSlash = removeBeginningSlash(str);
  const withoutBeginningAndTrailingSlash = removeTrailingSlash(
    withoutBeginningSlash
  );

  return withoutBeginningAndTrailingSlash;
}

/**
 * Add line breaks between lines
 * @param lines Array of strings (lines)
 * @returns JSX with <br /> between lines
 */
export function addLineBreaks(lines: Array<string>): React.ReactNode {
  return lines.map((line, index) => {
    const isLast = lines.length === index + 1;
    return !isLast ? (
      <>
        {line}
        <br />
      </>
    ) : (
      line
    );
  });
}
