import React, { useState } from "react";
import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import Button from "../../1-atoms/Button/Button";
import Clone from "../../0-electrons/Clone/Clone";
import NavMenu from "../../2-molecules/NavMenu/NavMenu";
import * as css from "./NavHeader.module.scss";

interface NavHeaderItemsProps {
  /** The current navigatin item */
  current: React.ReactElement;
  /** The children navigatin items */
  children: Array<React.ReactElement>;
}

export interface NavHeaderProps {
  /** The Buttons Label */
  items?: Array<NavHeaderItemsProps>;
  /** The NavigationsHeaders Logo */
  logo: React.ReactElement;
}

const not = (x: any) => !x;

const NavHeader: React.FC<NavHeaderProps> = ({
  logo,
  items,
}: NavHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(not);
    setIsActive(false);
  };

  return (
    <>
      <header className={getClassName(css, "NavHeader")}>
        <div className={getClassName(css, "NavHeader__fixed")}>
          <Clone node={logo} className={getClassName(css, "NavHeader__logo")} />
          <div className={getClassName(css, "NavHeader__buttonWrapper")}>
            <Button
              tag="button"
              className={getClassName(css, "NavHeader__button")}
              type={menuOpen ? "menu-close" : "menu-open"}
              onClick={toggleMenu}
            />
          </div>
        </div>
        <nav
          className={classNames(getClassName(css, "NavHeader__menu"), {
            [getClassName(css, "NavHeader__menu--open") as string]: menuOpen,
          })}
        >
          <NavMenu
            items={items}
            onClick={setIsActive}
            onClose={() => setIsActive(false)}
            onNavChange={toggleMenu}
            itemActive={isActive}
            // textLink="Wissen kompakt"
            // file="../../../data/next-gen-js-summary.pdf"
          />
        </nav>
      </header>
      <div className={getClassName(css, "NavHeader__spacer")} />
    </>
  );
};

export default NavHeader;
