import React from "react";
import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import * as css from "./Toggle.module.scss";

export interface ToggleProps {
  children: React.ReactNode | string;
  isActive?: boolean | undefined;
  isDisabled?: boolean | undefined;
  className?: string | null;
  onChange?: React.ChangeEventHandler;
}

export const Toggle: React.FC<ToggleProps> = ({
  children,
  isActive,
  isDisabled,
  className,
  onChange,
}: ToggleProps) => {
  return (
    <label
      className={classNames(className, getClassName(css, "Toggle"), {
        [getClassName(css, "Toggle--disabled") as string]: isDisabled === true,
      })}
    >
      <span className={getClassName(css, "Toggle__label")}>{children}</span>

      <input
        type="checkbox"
        checked={isActive === true ? true : false}
        onChange={onChange}
        disabled={isDisabled}
      />
      <span className={getClassName(css, "Toggle__slider")} />
    </label>
  );
};

export default Toggle;
