// TODO: add Typescript Typings
import React from "react";

import { default as _get } from "lodash/get";
import { Link, graphql, useStaticQuery } from "gatsby";

import { getClassName } from "../components/0-electrons/css";
import NavHeader from "../components/3-organisms/NavHeader/NavHeader";

import * as css from "../components/2-molecules/NavMenu/NavMenu.module.scss";

import { getRootUri } from "../helper/uri";

import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

const ContentfulNavigation = () => {
  const navData = useStaticQuery(graphql`
    {
      contentfulNavigation(title: { eq: "Main Navigation" }) {
        title
        items {
          ... on ContentfulComposeNavigationTree {
            contentfulparent {
              id
              itemTitle
              overrideTitle
              highlight
              item {
                ... on ContentfulCategoryPage {
                  title
                  slug
                }
                ... on ContentfulBlogPost {
                  title
                  slug
                }
              }
            }
            contentfulchildren {
              id
              itemTitle
              overrideTitle
              highlight
              item {
                ... on ContentfulCategoryPage {
                  title
                  slug
                }
                ... on ContentfulBlogPost {
                  title
                  slug
                }
                ... on ContentfulComposeNavigationItemMedia {
                  id
                  internal {
                    type
                  }
                  target {
                    id
                    file {
                      url
                    }
                  }
                  image {
                    id
                    gatsbyImageData(width: 67, height: 100, quality: 90)
                  }
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  const navItems = _get(navData, "contentfulNavigation.items", []);
  const navItemsUi = mapContentfulToUiComponent(navItems);
  const withStaticPages = [
    {
      current: createMainItem({
        slug: "/hauttest/",
        title: "Hauttest",
      }),
      highlight: true,
    },
    ...navItemsUi,
    {
      current: createMainItem({
        slug: "/apotheken/",
        title: "Apotheken",
      }),
      highlight: true,
    },
  ];

  return (
    <NavHeader
      items={withStaticPages}
      logo={
        <Link key="header-nav-home" to="/">
          <StaticImage
            src="../images/hautstark-logo-claim.png"
            placeholder="none"
            layout="constrained"
            width={180}
            quality={90}
            alt="Hautstark Logo"
          />
        </Link>
      }
    />
  );
};

function mapContentfulToUiComponent(items) {
  return items.map(mainLevel => {
    let subItems = [];

    subItems = mainLevel.contentfulchildren?.map(subLevel => {
      return createSubItem(subLevel);
    });

    return {
      current: createMainItem(mainLevel),
      highlight: _get(mainLevel, "contentfulparent.highlight", false) === true,
      children: subItems,
    };
  });
}

function getTitleWithOverride(
  title: string | null,
  overrideTitle: string | false | null,
  shouldOverride: boolean
) {
  const shouldOverrideTitle =
    shouldOverride === true &&
    typeof overrideTitle === "string" &&
    overrideTitle.length;

  return shouldOverrideTitle ? overrideTitle : title;
}

function createMainItem(item) {
  const slug = getRootUri(
    _get(item, "contentfulparent.item.slug", undefined) ||
      _get(item, "slug", undefined)
  );
  const title = getTitleWithOverride(
    _get(item, "title", null) ||
      _get(item, "contentfulparent.item.title", null),
    _get(item, "contentfulparent.itemTitle", false),
    _get(item, "contentfulparent.overrideTitle", false)
  );

  return <Link to={getRootUri(slug)}>{title}</Link>;
}

function createSubItem(subItem) {
  const slug = getRootUri(_get(subItem, "item.slug", undefined));
  const title = getTitleWithOverride(
    _get(subItem, "item.title", null) || _get(subItem, "itemTitle", false),
    _get(subItem, "itemTitle", false),
    _get(subItem, "overrideTitle", false)
  );

  const isMediaItem =
    _get(subItem, "item.internal.type", undefined) ===
    "ContentfulComposeNavigationItemMedia";
  if (isMediaItem) {
    const target = _get(subItem, "item.target.file.url", undefined);
    const text = _get(subItem, "item.text", undefined);
    const imageData = _get(subItem, "item.image.gatsbyImageData", undefined);

    return {
      type: "image",
      element: (
        <a href={target} target="_blank" rel="noreferrer">
          {title}
        </a>
      ),
      image: (
        <GatsbyImage className="img" image={imageData} alt={title as string} />
      ),
      text,
    };
  }

  return <Link to={getRootUri(slug)}>{title}</Link>;
}

export default ContentfulNavigation;
