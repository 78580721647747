import React from "react";

import classNames from "classnames";
import { default as _isEmpty } from "lodash/isEmpty";

import { getClassName } from "../css";

import * as css from "./Icon.module.scss";

import { ReactComponent as ArrowDown } from "./icons/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "./icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "./icons/arrow-right.svg";
import { ReactComponent as ArrowUp } from "./icons/arrow-up.svg";
import { ReactComponent as Cancel } from "./icons/cancel.svg";
import { ReactComponent as Check } from "./icons/check.svg";
import { ReactComponent as Clock } from "./icons/clock.svg";
import { ReactComponent as DirectionDown } from "./icons/direction-down.svg";
import { ReactComponent as DirectionLeft } from "./icons/direction-left.svg";
import { ReactComponent as DirectionRight } from "./icons/direction-right.svg";
import { ReactComponent as DirectionUp } from "./icons/direction-up.svg";
import { ReactComponent as Download } from "./icons/download.svg";
import { ReactComponent as Location } from "./icons/location.svg";
import { ReactComponent as LocationCampaign } from "./icons/location-campaign.svg";
import { ReactComponent as Letter } from "./icons/letter.svg";
import { ReactComponent as LinkExternal } from "./icons/link-external.svg";
import { ReactComponent as List } from "./icons/list.svg";
import { ReactComponent as Menu } from "./icons/menu.svg";
import { ReactComponent as Minus } from "./icons/minus.svg";
import { ReactComponent as Play } from "./icons/play.svg";
import { ReactComponent as PlayCircle } from "./icons/play-circle.svg";
import { ReactComponent as Plus } from "./icons/plus.svg";
import { ReactComponent as Share } from "./icons/share.svg";
import { ReactComponent as SocialWhatsApp } from "simple-icons/icons/whatsapp.svg";
import { ReactComponent as SocialFacebook } from "simple-icons/icons/facebook.svg";
import { ReactComponent as SocialFacebookMessage } from "simple-icons/icons/messenger.svg";
import { ReactComponent as SocialInstagram } from "simple-icons/icons/instagram.svg";
import { ReactComponent as Youtube } from "simple-icons/icons/youtube.svg";

export const Icons = {
  arrowDown: {
    Component: ArrowDown,
    width: 20,
    height: 20,
  },
  arrowLeft: {
    Component: ArrowLeft,
    width: 26,
    height: 26,
  },
  arrowRight: {
    Component: ArrowRight,
    width: 26,
    height: 26,
  },
  arrowUp: {
    Component: ArrowUp,
    width: 20,
    height: 20,
  },
  cancel: { Component: Cancel, width: 20, height: 20 },
  check: {
    Component: Check,
    width: 26,
    height: 26,
  },
  clock: { Component: Clock, width: 20, height: 20 },
  directionDown: { Component: DirectionDown, width: 20, height: 20 },
  directionLeft: { Component: DirectionLeft, width: 20, height: 20 },
  directionRight: { Component: DirectionRight, width: 20, height: 20 },
  directionUp: { Component: DirectionUp, width: 20, height: 20 },
  download: { Component: Download, width: 20, height: 20 },
  location: { Component: Location, width: 20, height: 20 },
  locationCampaign: {
    Component: LocationCampaign,
    width: 20,
    height: 31,
  },
  letter: { Component: Letter, width: 18, height: 12 },
  LinkExternal: { Component: LinkExternal, width: 20, height: 20 },
  list: { Component: List, width: 20, height: 20 },
  menu: { Component: Menu, width: 20, height: 20 },
  minus: { Component: Minus, width: 20, height: 20 },
  play: { Component: Play, width: 20, height: 20 },
  playCircle: { Component: PlayCircle, width: 26, height: 26 },
  plus: { Component: Plus, width: 20, height: 20 },
  share: { Component: Share, width: 20, height: 20 },
  socialFacebook: { Component: SocialFacebook, width: 23, height: 23 },
  socialFacebookMessage: {
    Component: SocialFacebookMessage,
    width: 23,
    height: 23,
  },
  socialWhatsapp: { Component: SocialWhatsApp, width: 23, height: 23 },
  socialInstagram: { Component: SocialInstagram, width: 23, height: 23 },
  socialYoutube: { Component: Youtube, width: 23, height: 23 },
};

const iconsAvailable = Object.keys(Icons);
const iconColors = [
  "primary",
  "secondary",
  "grey",
  "grey-50",
  "black",
  "white",
];

export interface IconProps {
  /** The Icon to use */
  type: typeof iconsAvailable[number];
  /** className to add */
  className?: string | null;
  /** If the icon should be fluid so that it can adapt to the parent container */
  fluid?: boolean;
  /** The color of the icon */
  color?: typeof iconsAvailable[number];
  /** onClick Function */
  onClick?: (e: MouseEvent) => void;
}

/**
 * Primary UI component for user interaction
 */
export const Icon: React.FC<IconProps> = ({
  type,
  className,
  fluid = false,
  color = "primary",
  onClick,
}: IconProps) => {
  const iconIsSet = typeof type === "string";

  if (!iconIsSet || !iconExists(type)) {
    console.warn(
      `You have to define the icon to use. Use on of these: ${iconsAvailable.join(
        ", "
      )}`
    );
    return null;
  }
  const iconToUse = Icons[type];
  const IconComponent: React.FC<IconProps> = iconToUse.Component;

  const classes = classNames(className, {
    [getClassName(css, `Icon--color-${color}`) as string]: !_isEmpty(color),
  });

  return (
    <IconComponent
      onClick={onClick}
      className={classes.length ? classes : null}
      style={
        fluid === true
          ? undefined
          : {
              width: iconToUse.width,
              height: iconToUse.height,
            }
      }
    />
  );
};

function iconExists(icon: IconProps["type"] | undefined | null): boolean {
  if (!icon) return false;

  return iconsAvailable.indexOf(icon) > -1;
}

export default Icon;
export { iconsAvailable, iconColors, iconExists };
