import React from "react";
import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";
import { Button } from "../../1-atoms/Button/Button.tsx";

import * as css from "./Notice.module.scss";

function Notice({ headline, children, ctaPrimary, ctaSecondary, isVisible }) {
  return isVisible ? (
    <div className={classNames(getClassName(css, "Notice"))}>
      <div className={getClassName(css, "Notice__textWrapper")}>
        <TypographyCopy
          size="small"
          className={getClassName(css, "Notice__headline")}
        >
          {headline}
        </TypographyCopy>
        <TypographyCopy
          size="small"
          className={getClassName(css, "Notice__content")}
        >
          {children}
        </TypographyCopy>
      </div>

      <div className={getClassName(css, "Notice__buttonWrapper")}>
        <Button
          type="primary"
          icon="arrowRight"
          tag="button"
          onClick={ctaPrimary.onClick}
          className={getClassName(css, "Notice__button")}
        >
          {ctaPrimary.label}
        </Button>
        <Button
          type="secondary"
          icon="arrowRight"
          tag="button"
          onClick={ctaSecondary.onClick}
          className={getClassName(css, "Notice__button")}
        >
          {ctaSecondary.label}
        </Button>
      </div>
    </div>
  ) : null;
}

export default Notice;
