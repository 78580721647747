import React, { MouseEventHandler } from "react";
import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import { Clone } from "../../0-electrons/Clone/Clone";
import { Icon } from "../../0-electrons/Icon/Icon";

import * as css from "./NavItem.module.scss";

export const navItemTypes = [
  "firstLevelParent",
  "firstLevelItem",
  "secondLevelParent",
  "secondLevelItem",
  "secondLevelItemImage",
];

export interface NavItemProps {
  /** The NavItems children */
  children?: React.ReactNode;
  /** The NavItems click function */
  onClick?: MouseEventHandler | undefined;
  /** The NavItems type, see navItemTypes */
  type?: typeof navItemTypes[number];
  /** If this nav item should be highlighted */
  highlight?: boolean;
  /** additional settings for use with type "secondLevelItemImage" */
  image?: {
    /** will be used for additional text item */
    text: string;
    img: React.ReactNode;
  };
}

export const NavItem: React.FC<NavItemProps> = ({
  children,
  onClick,
  type = "firstLevelParent",
  highlight,
  image,
}: NavItemProps) => {
  if (!React.isValidElement(children)) {
    console.warn(
      "NavItem: You have to provide a valid React Element as children"
    );
    return null;
  }

  const Tag = children.type;

  const typeSettings = {
    firstLevelParent: {
      icon: "directionRight",
      modifierClass: getClassName(css, "NavItem--firstLevelParent") as string,
    },
    firstLevelItem: {
      icon: false,
    },
    secondLevelParent: {
      icon: "directionRight",
      modifierClass: getClassName(css, "NavItem--secondLevelParent") as string,
    },
    secondLevelItem: {
      icon: false,
      modifierClass: getClassName(css, "NavItem--secondLevelItem") as string,
    },
    secondLevelItemImage: {
      icon: false,
      modifierClass: getClassName(
        css,
        "NavItem--secondLevelItemImage"
      ) as string,
    },
  };
  const settings = typeSettings[type]; // TODO: add TypeScript typings

  const navItemContent =
    type === "secondLevelItemImage" ? (
      <>
        <div className="text-container">
          <div className="menuItemText">{children.props.children}</div>
          <span className="linkText">
            {image?.text}
            <Icon className="icon" type="download" />
          </span>
        </div>
        {image?.img}
      </>
    ) : (
      <>
        <span>{children.props.children} </span>{" "}
        {typeof settings.icon === "string" ? (
          <Icon
            type={settings.icon}
            className={classNames(getClassName(css, "NavItem__icon"))}
          />
        ) : null}
      </>
    );

  return (
    <Clone
      onClick={onClick}
      node={
        <Tag
          {...{
            ...children.props,
            className:
              highlight === true
                ? getClassName(css, "NavItem--highlight")
                : undefined,
          }}
        >
          {navItemContent}
        </Tag>
      }
      className={classNames(
        getClassName(css, "NavItem"),
        settings.modifierClass
      )}
    />
  );
};

export default NavItem;
