import React from "react";

import { getClassName } from "../../0-electrons/css";

import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";

import * as css from "./Banner.module.scss";

export interface BannerProps {
  /** The title of tha banner*/
  title: string;
  /** The info paragraph*/
  info: string;
}

const Banner: React.FC<BannerProps> = ({ title, info, children }) => {
  return (
    <div className={getClassName(css, "Banner")}>
      {info ? (
        <TypographyHeadline
          size="h5"
          tag="h5"
          color="grey"
          className={getClassName(css, "Banner__info")}
        >
          {info}
        </TypographyHeadline>
      ) : null}
      <TypographyHeadline
        size="h5"
        tag="h5"
        color="grey"
        className={getClassName(css, "Banner__title")}
      >
        {title}
      </TypographyHeadline>
      {children}
    </div>
  );
};

export default Banner;
