// extracted by mini-css-extract-plugin
export var Button = "Button-module--Button--7mDKw";
export var ButtonBorderless = "Button-module--Button--borderless--5EOR2";
export var ButtonDisabled = "Button-module--Button--disabled---G7DR";
export var ButtonIconOnly = "Button-module--Button--icon-only--eDbpV";
export var ButtonModeCta = "Button-module--Button--mode-cta--Vrvjq";
export var ButtonModeInvert = "Button-module--Button--mode-invert--qyRWn";
export var ButtonShadow = "Button-module--Button--shadow--j5th3";
export var ButtonTypeMenuClose = "Button-module--Button--type-menu-close--7DyyB";
export var ButtonTypeMenuOpen = "Button-module--Button--type-menu-open--7BpDf";
export var ButtonTypePrimary = "Button-module--Button--type-primary--fCsZ6";
export var ButtonTypeSecondary = "Button-module--Button--type-secondary--OXTAn";
export var ButtonTypeSecondaryFill = "Button-module--Button--type-secondary-fill--YGTtE";
export var ButtonTypeText = "Button-module--Button--type-text--bMfPE";
export var Button__icon = "Button-module--Button__icon--REsli";
export var Button__iconAnimation = "Button-module--Button__icon--animation--fZyjn";
export var Button__iconAnimationDown = "Button-module--Button__icon--animation-down--3cgBE";
export var Button__iconAnimationLeft = "Button-module--Button__icon--animation-left--vOxOH";
export var Button__iconAnimationRight = "Button-module--Button__icon--animation-right--kV8n3";
export var Button__iconAnimationUp = "Button-module--Button__icon--animation-up--Y5dx9";
export var Button__iconNotSingle = "Button-module--Button__icon--not-single--5YLL1";