// extracted by mini-css-extract-plugin
export var copy = "TypographyCopy-module--copy--D+QN5";
export var copyColorBlue = "TypographyCopy-module--copy--color-blue--9TsPl";
export var copyColorGrey = "TypographyCopy-module--copy--color-grey--MaPLP";
export var copyColorWhite = "TypographyCopy-module--copy--color-white--iY4-3";
export var copySizeBig = "TypographyCopy-module--copy--size-big--sej9e";
export var copySizeBigResponsive = "TypographyCopy-module--copy--size-big-responsive--qNwBw";
export var copySizeDefault = "TypographyCopy-module--copy--size-default--6nYl1";
export var copySizeFootnote = "TypographyCopy-module--copy--size-footnote--OZRL+";
export var copySizeLarge = "TypographyCopy-module--copy--size-large--afrGd";
export var copySizeSmall = "TypographyCopy-module--copy--size-small--+tuIk";