import React, { useContext, useReducer } from "react";

import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import Link from "gatsby-link";

import CookieLayer from "../components/3-organisms/CookieLayer/CookieLayer";
import Notice from "../components/2-molecules/Notice/Notice";

import { cookieStore } from "./context";
import { setGatsbyGdprCookies } from "./cookies";

// used for internal state of CookieLayerGatsby
function internalReducer(state, action) {
  switch (action.type) {
    case "all":
      // console.log("reset to", action.payload);
      return action.payload;

    default:
      return { ...state, [action.payload]: !state[action.payload] };
  }
}

const CookieLayerGatsby = () => {
  // gatsby-plugin-gdpr-integration
  const location = useLocation();
  initializeAndTrack(location);

  // cookie context
  const cookieState = useContext(cookieStore);

  // internal state
  const [state, dispatch] = useReducer(internalReducer, {
    ...cookieState.state.cookies,
  });
  const updateInternalState = payload => dispatch({ type: "all", payload });
  const resetInternalState = () =>
    updateInternalState({
      ...cookieState.state.cookies,
    });

  // modal and notice handling
  // helper functions
  const noticeClose = () => cookieState.toggleCookieNotice(false);
  const modalClose = () => {
    cookieState.toggleCookieDialog(false);
  };
  const modalOpen = () => cookieState.toggleCookieDialog(true);
  const closeModalAndNotice = () => {
    modalClose();
    noticeClose();
  };

  const cancel = () => {
    resetInternalState();
    modalClose();
  };

  const approveAll = () => {
    cookieState.approveAllCookies();
    closeModalAndNotice();
    setGatsbyGdprCookies();
  };

  // uses current state
  const approveSome = () => {
    cookieState.changeCookie(state);
    closeModalAndNotice();
    setGatsbyGdprCookies();
  };

  return (
    <>
      <Notice
        headline="Datenschutz & Cookies"
        ctaPrimary={{
          label: "Ich stimme zu",
          onClick: approveAll,
        }}
        ctaSecondary={{
          label: "Cookie-Einstellungen",
          onClick: modalOpen,
        }}
        isVisible={cookieState.state.isOpenNotice}
      >
        #hautstark nutzt Cookies und andere Tracking-Tools, um die Performance
        und Funktionalität der Webseite zu optimieren, den Traffic auf der
        Webseite zu messen und ihre Inhalte Ihren Interessenten anzupasen
        (Werbung). Mehr Informationen über die Verarbeitung persönlicher Daten
        und Ihre Rechte finden Sie in{" "}
        <Link to="/datenschutz/">unseren Datenschutzbestimmungen</Link>.
      </Notice>
      <CookieLayer
        modalTitle="Cookie-Einstellungen"
        isVisible={cookieState.state.isOpenDialog}
        onClose={cancel}
        onApprove={approveSome}
        tabs={[
          {
            label: "Notwendige",
            headline: "Notwendige Cookies",
            isDisabled: true,
            value: state.required,
            toggleOption: "Zwingend",
            content: `Der Einsatz dieser Cookies ist zwingend, um das ordnungsgemäße Funktionieren der Website sicherzustellen.`,
          },
          {
            label: "Statistik",
            headline: "Statistik Cookies",
            value: state.statistics,
            onChange: () => dispatch({ payload: "statistics" }),
            toggleOption: "Optional",
            content: `Anhand dieser Cookies können wir verstehen, wie die Besucher unsere Seite benutzen, insbesondere welche Bereiche sie besuchen und wie viel Zeit sie auf der Seite verbringen.
Sie werden nur genutzt, um die Website zu verbessern.`,
          },
          // {
          //   label: "Marketing",
          //   toggleOption: "Optional",
          //   content: `Diese Cookies ermöglichen es Ihnen Werbung basierend auf Ihren Interessen außerhalb unserer Seite zu versenden. Zusätzlich nutzen wir Dienstleistungen Dritter auf unseren Produktseiten, um eine Verbindung zu sozialen Netzwerken wie Facebook über “Like” und “Share” Buttons herzustellen.`,
          // },
        ]}
      />
    </>
  );
};

export default CookieLayerGatsby;
