import { default as _get } from "lodash/get";
import { removeBeginningAndTrailingSlash } from "./strings";

/**
 * Used to get links from a Contentful Reference field
 *
 * TODO: TS Typings
 */
export function getInternalLinkByReference(reference) {
  const linkTarget = _get(reference, "slug", undefined);

  if (typeof linkTarget === "undefined") return false;

  return getRootUri(linkTarget);
}

export function getRootUri(uri?: string): string {
  return typeof uri === "string" && uri.length
    ? `/${removeBeginningAndTrailingSlash(uri)}/`
    : "/";
}

export function getUrl(slug?: string): string {
  return `${process.env.GATSBY_SITE_URL}${
    typeof slug === "string" && slug.length ? getRootUri(slug) : ""
  }`;
}

export function isExternalUri(uri: string): boolean {
  return (
    typeof uri === "string" &&
    uri.includes("http") &&
    !uri.includes(process.env.GATSBY_SITE_URL as string)
  );
}

export function removeSiteUrl(url: string): string {
  return getRootUri(url.replace(process.env.GATSBY_SITE_URL as string, ""));
}
